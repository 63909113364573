import { contactCardData } from "../utils/data/contactCardData";

export default function ContactCard() {
    return(
    <div className="flex flex-col">
    {contactCardData.map((contact) => (
        <div key={contact.id} className="md:w-100 border md:h-52 h-64 md:ms-52 mx-4 rounded-xl my-4 hover:-translate-y-2 transition-transform hover:shadow-sm flex flex-row">
            <contact.icon className="text-green-500 bg-gray-200 rounded-full ms-4 mt-8 w-24 h-12 p-2" />
            <div className="flex flex-col ms-8 mt-8">
                <h1 className="text-2xl font-bold">{contact.label}</h1>
                <h3 className="mt-2 text-md font-semibold">{contact.designation}</h3>
                <h5 className="mt-2 font-semibold text-sm text-gray-500">{contact.address}</h5>
            </div>
        </div>
    ))}
    </div>
);
}