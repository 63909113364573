import { useState, useEffect, useCallback } from "react";
import { CiCircleChevLeft, CiCircleChevRight } from "react-icons/ci";
import { FaStar } from "react-icons/fa";
import quoteImg from "../utils/images/Icon.png";
import { comments } from "../utils/data/commentsData";

const ClientSlider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [itemsToShow, setItemsToShow] = useState(3);
    const [isTransitioning, setIsTransitioning] = useState(false);

    const nextSlide = useCallback(() => {
      if (!isTransitioning) {
        setIsTransitioning(true);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }
    }, [isTransitioning]);

    const prevSlide = useCallback(() => {
      if (!isTransitioning) {
        setIsTransitioning(true);
        setCurrentIndex((prevIndex) => prevIndex - 1);
      }
    }, [isTransitioning]);

    useEffect(() => {
      const handleResize = () => {
        setItemsToShow(window.innerWidth < 768 ? 1 : 3);
      };

      window.addEventListener("resize", handleResize);
      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
      if (isTransitioning) {
        const resetTransition = () => setIsTransitioning(false);

        if (currentIndex >= comments.length) {
          setTimeout(() => {
            setCurrentIndex(0);
            resetTransition();
          }, 500);
        } else if (currentIndex < 0) {
          setTimeout(() => {
            setCurrentIndex(comments.length - 1);
            resetTransition();
          }, 500);
        } else {
          setTimeout(resetTransition, 500);
        }
      }
    }, [currentIndex, isTransitioning]);

    return (
      <div className="flex md:flex-row flex-col justify-evenly my-24 pl-4 pr-4">
        <div className="flex-col flex md:py-12">
          <div className="text-center md:text-left text-2xl font-extrabold md:w-44 self-center">
            What Our Clients Say About Us:
          </div>
          <div className="flex md:mt-12 mt-6 md:self-start self-center">
            <button onClick={prevSlide} aria-label="Previous Slide">
              <CiCircleChevLeft className="text-4xl hover:text-primary" />
            </button>
            <button onClick={nextSlide} aria-label="Next Slide">
              <CiCircleChevRight className="text-4xl hover:text-primary" />
            </button>
          </div>
        </div>
        <div className="relative md:w-[1150px] overflow-hidden py-12">
          <div
            className={`flex ${
              isTransitioning
                ? "transition-transform duration-500 ease-in-out"
                : ""
            }`}
            style={{
              transform: `translateX(-${(currentIndex * 100) / itemsToShow}%)`,
            }}
          >
          {comments.concat(comments).map((comment, index) => (
            <div key={index} className="flex flex-col">
              <div
                className="w-[400px] h-full md:h-[170px] ml-0 mr-4 md:mx-4 rounded-xl shadow-md flex-shrink-0 pb-4 border border-solid border-[#fbfbfb]"
              >
                <img
                  src={quoteImg}
                  alt="quote"
                  width={50}
                  className="rounded-md shadow-md shadow-green-200 -translate-y-5 ms-5"
                  loading="lazy"
                />
                <div className="font-medium text-gray-500 text-md mx-5 leading-6">
                  {comment.text}
                </div>
                <div className="flex absolute mx-5 bottom-4 md:bottom-16">
                  {[...Array(comment.stars)].map((_, i) => (
                    <FaStar key={i} className="text-yellow-500" />
                  ))}
                </div>
              </div>
              <div className="font-semibold text-md ms-8 mt-4 text-gray-800">
                {comment.author}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClientSlider;
