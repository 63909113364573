import styled from "styled-components";

export const Heading = styled.h1`
  text-align: center;
  color: green;
`;

export const Content = styled.div`
  overflow-y: scroll; /* Corrected property name */
  height: 100vh; /* Changed to standard unit */
`;

export const Button = styled.div`
  position: fixed;
  right: 2%;
  bottom: 30%;
  font-size: 3rem;
  z-index: 1000; /* Increased z-index for higher priority */
  cursor: pointer;
  color: var(--primary-color);
  display: flex; /* Flexbox can help with alignment if needed */
  align-items: center;
  justify-content: center;
`;
